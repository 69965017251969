import React from 'react'

const BenefitRep: React.FC = () => {
  return (
    <div className='w-[90%] h-24  flex bg-[#EDF0F4] rounded-lg shadow-neu2 '>
      <div className='w-[30%] h-full flex justify-center items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" width="66" height="64" viewBox="0 0 66 64" fill="none">
          <mask id="path-1-inside-1_134_2094" fill="white">
            <path d="M43.1895 26.96L43.1894 8.66669L32.8945 8.66669L22.5995 8.66669L22.5996 26.9867"/>
          </mask>
          <path d="M43.1895 26.96L43.1894 8.66669L32.8945 8.66669L22.5995 8.66669L22.5996 26.9867" fill="#C6C6C6"/>
          <path d="M40.1895 26.96C40.1895 28.6169 41.5327 29.96 43.1895 29.96C44.8464 29.96 46.1895 28.6169 46.1895 26.96L40.1895 26.96ZM43.1894 8.66669L46.1894 8.66668C46.1894 7.00983 44.8463 5.66669 43.1894 5.66669L43.1894 8.66669ZM32.8945 8.66669L32.8945 5.66669L32.8945 8.66669ZM22.5995 8.66669L22.5996 5.66669C20.9427 5.66669 19.5996 7.00984 19.5995 8.66669L22.5995 8.66669ZM19.5996 26.9867C19.5996 28.6435 20.9427 29.9867 22.5995 29.9867C24.2564 29.9867 25.5996 28.6435 25.5996 26.9867L19.5996 26.9867ZM46.1895 26.96L46.1894 8.66668L40.1894 8.66671L40.1895 26.96L46.1895 26.96ZM43.1894 5.66669L32.8945 5.66669L32.8945 11.6667L43.1894 11.6667L43.1894 5.66669ZM32.8945 5.66669L22.5996 5.66669L22.5995 11.6667L32.8945 11.6667L32.8945 5.66669ZM19.5995 8.66669L19.5996 26.9867L25.5996 26.9867L25.5995 8.6667L19.5995 8.66669Z" fill="#B1B1B1" mask="url(#path-1-inside-1_134_2094)"/>
          <path d="M32.8945 24C22.3931 24 13.88 32.3574 13.88 42.6667C13.88 52.976 22.3931 61.3334 32.8945 61.3334C43.3959 61.3334 51.909 52.976 51.909 42.6667C51.909 32.3574 43.396 24 32.8945 24Z" fill="#00AF92" stroke="#E0E4E5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M28.5099 50.3531C27.7685 50.7697 26.8737 50.1531 27.0208 49.327L27.9206 44.2769C27.9785 43.9516 27.8684 43.6202 27.6269 43.3928L23.9505 39.9311C23.3583 39.3735 23.6616 38.3866 24.4706 38.2377C26.2399 37.9121 27.667 37.6495 29.6706 37.2808C29.987 37.2226 30.2575 37.0194 30.3981 36.734L32.7357 31.9876C33.1032 31.2415 34.1805 31.2347 34.5487 31.9762L36.7612 36.4313C36.9123 36.7355 37.2115 36.9417 37.5543 36.9778L42.5742 37.5067C43.4097 37.5947 43.7693 38.5917 43.1793 39.1845L39.4265 42.9557C39.2009 43.1825 39.0981 43.5008 39.1499 43.8128L40.0003 48.94C40.1326 49.7375 39.2901 50.3524 38.5546 49.9951L33.989 47.777C33.6891 47.6313 33.3342 47.6423 33.0423 47.8063L28.5099 50.3531Z" fill="#FAFAFA"/>
        </svg>
      </div>
      <div className='w-[70%] h-full flex flex-col justify-center items-center pl-2'>
        <div className='w-full h-4 text-xs-sm'>100주 이상 소유</div>
        <div className='w-full h-auto font-semibold'>호텔 어라이브 멤버십</div>
      </div>
    </div>
  )
}

export default BenefitRep;