import WalletConnect from "./WalletConnect";
import SubscriptionAd from "./SubscriptionAd";
import LineTypeTabComponent from "../../../components/tabUI/LineTypeTabComponent";
import HomeIndices from "../homeIndices";



export default function HomeAds(){


    
    return(
        <div className="" >
            <WalletConnect />
            <SubscriptionAd />
        </div>
    )

}